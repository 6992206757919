nav {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    background-color: var(--lightB);
    padding: 10px 0;
    border-bottom: 2px solid var(--darkA);
    z-index: 9999;
    display: flex;
    justify-content: space-between;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left img {
  display: inline-block;
  width: 90px;
  height: 40px;
  margin: 0px 10px 0px 20px;
}

.navbar-left .site-name {
  display: inline-block;
  font-size: 20px;
  margin-left: 10px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0px 50px 0px 0px;
  font-size: 20px;
}

.navbar-right ul li {
  display: inline-block;
}

.navbar-right ul li a {
  color: #000000;
  text-decoration: none;
  padding: 0px 20px;
}

.navbar-right ul li a:hover {
  background-color: var(--lightA);
}

.card {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  border: 2px solid #000000;
  padding: 2rem;
  margin: 1rem 6rem;
}

.card .left-column {
  width: calc(80% - 1rem);
  padding-right: 1rem;
  border-right: 1px solid #000000;
}

.card .right-column {
  width: calc(20% - 1rem);
  padding-left: 1rem;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    width: 100%;
  }

  .navbar-left {
    width: 100%;
    padding: 0 20px;
  }

  .navbar-left img {
    margin: 10px 0;
    width: 70px;
    height: auto;
  }

  .navbar-left .site-name {
    margin-left: 2rem;
    font-size: 1rem;
  }

  .navbar-right {
    width: 100%;
    justify-content: center;
  }

  .navbar-right ul {
    padding: 0;
    text-align: center;
  }

  .navbar-right ul li {
    display: block;
    margin: 10px 0;
  }

  .card {
    flex-direction: column;
    margin: 1rem 2rem;
  }

  .card .left-column,
  .card .right-column {
    width: 100%;
    padding: 1rem 0;
    border: none;
  }

  .card .left-column {
    border-bottom: 1px solid #000000;
  }

  .card .right-column {
    padding-top: 1rem;
  }
}