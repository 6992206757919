/* :root {
  --lightA: #EADFB4;
  --lightB: #9BB0C1;
  --darkB: #51829B;
  --darkA: #F6995C;
} */

/* :root {
  --lightA: #F1EEDC;
  --lightB: #BED7DC;
  --darkA: #E5DDC5;
  --darkB: #B3C8CF;
} */

/* :root {
  --lightA: #F1EEDC;
  --lightB: #F3F8FF;
  --darkA: #E5DDC5;
  --darkB: #DEECFF;
} */

:root {
  --lightA: #FBF9F1;
  --lightB: #F3F8FF;
  --darkA: #E5E1DA;
  --darkB: #DEECFF;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
