.page {
  width: 100%;
  height: max-content;
  background-color: var(--lightB);
}

.page:first-of-type {
  padding-top: 2rem;
}

.home > header {
  position: relative;
  text-align: center;
  padding: 2rem 2rem 2rem 2rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
}

.home > header h1 {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--lightA);
}

.home .quick-access-area {
  text-align: center;
}

.home .utc-time {
  font-size: 2.2rem;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.home .quick-access-header {
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: #333;
}

.home .panel-container {
  display: grid;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

/* Desktop view: 3x2 grid */
@media (min-width: 1024px) {
  .home .panel-container {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto); /* Adjust row height as needed */
  }
}

/* Mobile view: 6x1 grid */
@media (max-width: 1024px) {
  .home .panel-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, auto);
      gap: 3.5rem;
  }
}

.home .panel {
  background-color: var(--darkB);
  border-radius: 20px;
  padding: 2.2rem;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-height: 20rem;
  height: 100%;
  flex-direction: column;
}

.home .panel-image {
  object-position: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: -10px;
}

.home .panel-text {
  font-size: 1.5rem;
  margin: 0.25rem;
  text-align: center;
}

.about {
  max-width: 100%;
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.about > div {
  background-color: var(--lightA);
  width: 90%;
  max-width: 75rem;
  height: 45rem;
  padding: 1rem;
  border-radius: 1rem;
}

.home .about div h2 {
  font-size: 2rem;
}

.home .about div p {
  font-size: 1.2rem;
  text-align: justify;
  padding: 0rem 1.5rem 0rem 1.5rem;
}

.view-page-link {
  font-size: 2rem;
  font-weight: 750;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.view-page-link:hover {
  color: var(--lightB);
}

/* Mobile view */
@media (max-width: 1024px) {
  .home .about div p {
    font-size: 1rem;
  }
}

.view-page-link {
  font-size: 2rem;
  font-weight: 750;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.view-page-link:hover {
  color: var(--lightB);
}

.home-card-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 2rem 0rem 0rem 4rem;
  width: 100%;
}

/* Mobile view */
@media (max-width: 1024px) {
  .home-card-container {
      flex-direction: column;
      padding: 1rem; /* Adjust padding for mobile view */
      gap: 1rem; /* Reduce gap for mobile view */
      padding: 2rem 1rem; /* Adjust padding for mobile view */
  }
}

.link-card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: var(--darkA);
  max-width: 20rem;
  border-radius: 2rem;
  text-align: center;
}

.link-card > div {
  flex: auto;
  text-align: center;
}

.link-card h2 {
  font-size: 2rem;
}

.link-card p {
  font-size: 1.6rem;
}

.link-card span:first-of-type {
  font-size: 4rem;
}

.link-card span:last-of-type {
  font-size: 1.6rem;
  font-weight: 900;
}

/* Join Us section */
@media (min-width: 1024px) {
  .join-us {
    column-gap: 8rem;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    padding: 2rem;
  }
}

@media (max-width: 1024px) {
  .join-us {
    margin-top: 55rem;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .join-us-left, .join-us-right {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.join-us div {
  background-color: var(--lightA);
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
}

.qualification {
  display: flex;
}

.qualification:not(:last-child) {
  margin-bottom: 1rem;
}

.qualification span {
  height: fit-content;
  font-size: 3rem;
  margin-right: 1rem;
  background-color: #333;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.qualification h3 {
  font-size: 1.2rem;
  margin: 0;
}

.qualification p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.action {
  align-content: center;
  text-align: center;
}

.action h2 {
  margin: 0 0 1rem 0;
}

.join-us-right h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.join-us-right p {
  font-size: 1.2rem;
  text-align: center;
}

.join-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #333;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 1.2rem;
}

.join-button:hover {
  background-color: var(--darkA);
}

/* FORECAST PAGE */
.forecast-page {
  width: 100%;
  background-color: var(--lightB);
  padding-top: 2rem;
}

.forecast-section {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10rem;
}

@media (max-width: 1024px) {
  .forecast-page {
    margin-top: 12rem;
    width: 100%;
    background-color: var(--lightB);
    padding-top: 2rem;
  }

  .forecast-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .forecast-options-panel,
  .forecast-image-container,
  .forecast-domain-panel {
    flex: 1 1 100%;
    margin: 1rem 0;
  }

  .forecast-image-holder img {
    height: auto;
  }
}

.forecast-options-panel {
  flex: 0 0 15%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  background-color: var(--lightA);
  padding: 1rem;
  margin-right: 1rem;
}

.forecast-options-panel button {
  width: calc(100% - 1rem);
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--darkA);
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.forecast-options-panel button:hover {
  background-color: var(--darkB);
  transform: translateY(-2px);
}

.forecast-image-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 70%;
}

.forecast-image-holder {
  position: relative;
}

.forecast-image-holder img {
  width: 100%;
  height: auto;
  height: 85vh;
}

.forecast-slider-holder {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.forecast-slider-holder input[type="range"] {
  width: 100%;
}

.forecast-domain-panel {
  flex: 0 0 9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--lightA);
  padding: 1rem;
  margin-left: 1rem;
}

.forecast-domain-panel button {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--darkA);
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.forecast-domain-panel button:hover {
  background-color: var(--darkB);
  transform: translateY(-2px);
}

.forecast-about-container {
  background-color: var(--darkA);
  padding: 2rem;
}

.forecast-about-container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.forecast-about-container p {
  font-size: 1.1rem;
  line-height: 1.6rem;
}

/* ANALYSIS PAGE */
.analysis-page {
  width: 100%;
  background-color: var(--lightB);
  padding-top: 2rem;
}

.analysis-section {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;
}

.analysis-controls {
  gap: 2rem;
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.analysis-control-button {
  padding: 1rem 2rem;
  background-color: var(--darkA);
  color: #333;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.analysis-control-button:hover {
  background-color: var(--darkB);
  transform: translateY(-2px);
}

.analysis-image-holder {
  width: 85%;
  margin-right: 2rem;
}

.analysis-image-holder img {
  width: 100%;
  height: auto;
  max-height: 90vh;
}

.analysis-date-selector {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.analysis-date-selector select {
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--darkA);
  color: #333;
  cursor: pointer;
  margin-bottom: 1rem;
}

.analysis-date-selector select:hover {
  background-color: var(--darkB);
}

.analysis-date-selector label {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.analysis-about-container {
  background-color: var(--darkA);
  padding: 2rem;
}

.analysis-about-container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.analysis-about-container p {
  font-size: 1.1rem;
  line-height: 1.6rem;
}

/* Mobile view: */
@media (max-width: 1024px) {
  .analysis-page {
    width: 100%;
    background-color: var(--lightB);
    padding-top: 2rem;
    margin-top: 12rem;
  }

  .analysis-section {
    flex-direction: column;
    align-items: center;
  }

  .analysis-image-holder,
  .analysis-date-selector {
    width: 100%;
    margin: 1rem 0;
  }

  .analysis-image-holder img {
    height: auto; /* Adjust to maintain aspect ratio */
  }
}

/* Papers section */
.card {
  border: 0.15rem solid #333;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin: 2rem auto;
}

.left-column {
  flex: 0 0 75%;
  text-align: justify;
}

.right-column {
  flex: 0 0 15%;
}

.download-column {
  flex: 0 0 10%;
  display: flex;
}

.download-column a {
  display: flex;
  justify-content: center;
}

.download-column a img {
  width: 75%;
  height: auto;
}

@media (max-width: 1024px) {
  .page:first-of-type{
    margin-top: 12rem;
  }
}

/* Footer section */
.footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  height: 3rem;
  width: 100%;
}

@media screen and (max-width: 1024px) {

}
